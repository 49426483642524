import gql from "graphql-tag";

export const NOTES = gql`
  query FilterWithPaginateNotes($criteria: NoteFilterCriteria!, $pagination: Pagination!) {
    filterWithPaginateNotes(criteria: $criteria, pagination: $pagination) {
      content {
        id
        title
        importance
        url
        body
        description
        owner {
          id
          firstName
          lastName
          profile {
            imageUrl
          }
          employment {
            branch {
              name
            }
          }
        }
      }
      pageInfo {
        totalElements
      }
    }
  }
`;
